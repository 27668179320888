.title{
    color:rgb(134,108,237);
    font-weight: 700;
    font-size: 1.7em;
    margin-left: 5%;
    margin-top: 5%;
    margin-bottom: 5%;
}
.content{
    font-size: 1.2em;
    font-weight: 400;
    margin-left: 5%;
}
.search{
    width: 100vw;
    height: 4.5vh;
    padding-left: 12vw;
    margin: 30px 25px ;
    font-size: 1em;
    text-align: 20px;
    border: 1px solid;
    border-color: #EEEEEE;
    border-radius: 20px;
    box-shadow: 1.5px 1.5px 1.5px 1.5px rgba(0,0,0,0.2);
    position: relative;
}
.container{
    width: 100vw;
    height:100vh;
    display: flex;
    flex-direction: column;
}
.imageContainer{
    height: 12vh;
    position: relative;
    display: flex;
}
.image{
    width: 2.75vh;
    height: 2.75vh;
    position: absolute;
    top: 4.65vh;
    left: 40px;
    z-index: 99;
}
.mapContainer{
    background-color:rgb(185,171,245);
    height: 80vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    position:relative;
}
.onMap{
    position:absolute;
    z-index: 99;
    width: 30%;
    background-color: white;
}