.container{
    display:flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;

}
.filterbox{
    width:85vw;
    // height : 43vh;
    padding-bottom: 15px;
    background-color: white;
    border-radius: 10px;
    //align-items:center;
    box-shadow: 0px 1px 1px 1px rgba(0,0,0,0.25);
    margin-top : 10px;

}

.optiontitle{
    width:85vw;
    height : 40vh;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 4px 4px 4px rgba(0,0,0,0.25);
    
    //margin-top : 40px;

}

.buttonContainer{
    display: flex;
    justify-content: center;
    width: 100%;
    height: 22px;

    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.button{
    
    background-color:#DADADA;
    font-weight: 400;
    color: black;
    font-size: 0.85em;
    width: 56px;
    height: 22px;
    line-height: 22px;
    margin-right: 5px;
    border-radius: 10px;
    text-align: center;
    
    

}


.slider {
  margin-left: 5%;
  width: 90%;
  height: 10px;
  background-color:#775EEE;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 20px;

  .thumb {
    width: 23px;
    height: 23px;
    top: -6px;
    cursor: pointer;
    background: white;
    border : 1px solid;
    border-color: #775EEE;
    border-radius: 50%;
  }
  
}
.applybutton{

    gap: 10px;
    text-align: center;
    line-height: 36px;
    background: #775EEE;
    color:white;
    border-radius: 10px;
    width: 86%;
    margin-left: 7%;
    height: 36px;

}

