.container{
    display:flex;
    flex-direction:column;
    align-items:center;
    width: 85vw;
}

.infobox {
    width:85vw;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 4px 4px 4px rgba(0,0,0,0.25);
    margin : 10px;
    margin-bottom: 30px;
}



.keywordbox{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 20px;
    gap: 9px;
    
    width: 260px;
    height: 20px;


    background: #FFFFFF;
}

.keywordCircle{

    padding: 6px 6px;
    gap: 10px;
    text-align: center;
    line-height: 24px;
    width: 20px;
    height: 20px;
    font-size: 10px;

    background: #D9D9D9;
    border-radius: 50px;
    z-index: 99;
    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}
.keywordCircle{

    gap: 10px;
    text-align: center;
    line-height: 24px;
    width: 20px;
    height: 20px;
    font-size: 10px;

    background: #D9D9D9;
    border-radius: 50px;
    z-index: 99;
    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}
.addressContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top:20px;
}






.latestContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 5px 5px 5px;
    gap:0px;

}


.line{
    //position: absolute;
    width: 331px;
    height: 0px;
    // left: 0px;
    // top: 205px;

    border: 1px solid #D9D9D9;
    transform: rotate(0.17deg);
}

.categorybox{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 20px 0px 25px;
    gap: 20px;

    width: 330;
    height: 30px;

    background:#FFFFFF;

}
.manageinfobox{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding:13px 20px 0px 25px;
    gap: 10px;
    //position: absolute;
    width: 288px;
    height: 140px;
    // left: 22px;
    // top: 213px;
}
.manageinfobox2{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding:30px 20px 0px 25px;
    gap: 10px;
    //position: absolute;
    width: 330px;
    height: 90px;
    // left: 22px;
    // top: 213px;
}

.text{
    font-size: 13px;
    font-weight: 400;
}